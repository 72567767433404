.basket__send-button {
    width: 160px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    color: black;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

.basket__send-button-small {
    padding: 8px 4px;
    font-size: 12px;
    line-height: 14px;
    color: black;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

.not-loading {
    cursor: pointer;
    padding: 10px 16px;
    background-color: #FFFFFF;
}

.loading {
    cursor: default;
    padding: 6px 24px;
    background-color: #E6E6E6;
}
.basket__products-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px;
}

@media (min-width: 480px) {
    .basket__products-list {
        margin: 0 20px;
    }
}

.basket__shop-totals {
    width: 100%;
    padding: 14px 24px;
}

.basket__line {
    display: flex;
    justify-content: space-between;
}

.basket__line p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

@media (min-width: 480px) {
    .basket__line p {
        font-size: 20px;
        line-height: 24px;
    }
}

.basket__red {
    margin-top: 10px;
    color: var(--main-accent);
}
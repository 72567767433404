.move-top {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 9px 30px rgba(0, 0, 0, 0.25));
    position: fixed;
    z-index: 100;
    background-color: white;
    bottom: 16px;
    right: 16px;
    user-select: none;
}

.move-top__none {
    display: none;
}

@media (min-width: 480px) {
    .move-top {
        width: 80px;
        height: 80px;
        bottom: 26px;
        right: 26px;
    }
}

.move-top__image {
    width: 20px;
    height: 20px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/arrow-top.svg);
    mask-repeat: no-repeat;
}

@media (min-width: 480px) {
    .move-top__image {
        width: 28px;
        height: 28px;
    }
}

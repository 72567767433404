.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 26px 18px 18px;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 50;
}

@media (min-width: 480px) {
    .header {
        padding: 24px 30px 24px 24px;
    }
}

.header__left {
    display: flex;
    align-items: center;
}

.header__back {
    font-weight: 900;
    font-size: 29px;
    color: white;
    cursor: pointer;
    margin-right: 16px;
}

.basket__send-button-head {
    width: fit-content;
    font-size: 16px;
    line-height: 20px;
    margin-left: auto;
    color: black;
    padding: 12px 24px;
    cursor: pointer;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    background-color: white;
    text-transform: uppercase;
}

.header__shadow {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
}

.header__magnit-icon-wrapper {
    height: 40px;
    width: 110px;
    min-width: 110px;
    min-height: 40px;
    margin-right: 16px;
}

.header__magnit-icon {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: left;
}

.header__title {
    font-family: 'Geometria', sans-serif;
    font-weight: 800;
    font-size: 22px;
    color: var(--white);
}

@media (min-width: 480px) {
    .header__title {
        font-size: 29px;
    }
}

.header__close-icon {
    width: 20px;
    height: 20px;
    background-color: var(--white);
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    margin-left: auto;
}

.header__basket-button {
    cursor: pointer;
    margin-left: auto;
    position: relative;
}

.header__basket-icon {
    width: 18px;
    height: 22px;
    background-color: var(--white);
    mask-image: url(/public/img/basket.svg);
    mask-repeat: no-repeat;
}

@media (min-width: 480px) {
    .header__basket-icon {
        width: 24px;
        height: 30px;
    }
}

.header__basket-count {
    position: absolute;
    top: -15px;
    right: -20px;
    min-width: 24px;
    text-align: center;
    border-radius: 30px;
    color: var(--white);
    background-color: var(--main-accent);
    font-size: 14px;
    line-height: 20px;
    padding: 2px 8px;
}

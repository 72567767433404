.wrapperMain {
    width: 100vw;
    overflow-x: hidden;
}

.wrapperContent {
    background-color: var(--white);
    border-radius: 20px;
    padding: 16px 0;
    overflow: hidden;
}

@media (min-width: 480px) {
    .wrapperContent {
        border-radius: 40px;
        padding: 20px 0;
    }
}

.no-padding {
    padding: 0;
    margin-bottom: 0;
}
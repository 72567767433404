* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Geometria';
}

button, a {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 0;
    font-family: sans-serif;
    font-weight: normal;
    font-size: inherit;
    text-decoration: none;
    background-color: unset;
    cursor: pointer;
}


.send_like_list {
    text-decoration: none;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 120%;
    color: black;
    cursor: pointer;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

/* .header_HOME_PAGE{
  margin-top: 1%;
  border-radius: 30px;
  max-width: 435px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  background: url(../public/img/back.svg) no-repeat top left;
  background-size: cover;
} */

.container_MAGNIT_HOME {
    margin-top: 30px;
    width: 100%;
    height: 219px;
    background: #FAECDB;
    border-radius: 30px;
}

.container_MAGNIT_EXTRA {
    margin-top: 20px;
    width: 100%;
    height: 219px;
    background: #FFED00;
    border-radius: 20px;
}

.container_MAGNIT_COSMETIC {
    margin-top: 20px;
    width: 100%;
    height: 219px;
    background: #FBDFEB;
    border-radius: 20px;
}

.container_MAGNIT_PHATMACY {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    height: 219px;
    background: #DAF2F4;
    border-radius: 20px;
}

.name_catalog {
    padding-top: 40px;
    padding-left: 40px;
}

.catalog_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-top: 30px;
    padding-right: 40px;
}

.text_catalog {
    padding-right: 10px;
    width: 239px;
    height: 60px;
    text-decoration: none;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 120%;
    color: black;
}

.img_HOME_PAGE {
    padding-top: 25px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.display-none {
    display: none;
}


.img_reverse {
    transform: scale(1, -1);
}

.container {
    max-width: 100%;
    margin-top: 70px;
    height: auto;
}

.overflow_hidden {
    overflow: hidden;
}

.header_fix {
    position: fixed;
    width: 100%;
    height: 70px;
    top: 0;
    left: 0;
    z-index: 3;
}

.container_header {
    background: white;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 12px solid rgba(237, 25, 37, 1);
    height: 70px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

.container_header_HOME {
    background: white;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 12px solid rgba(250, 236, 219, 1);
    height: 70px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

.container_header_EXTRA {
    background: white;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 12px solid rgba(255, 237, 0, 0.5);
    height: 70px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

.container_header_COSMETIC {
    background: white;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 12px solid rgba(251, 223, 235, 1);
    height: 70px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

.container_header_PHARMACY {
    background: white;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 12px solid rgba(218, 242, 244, 1);
    height: 70px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

.line_MAGNIT_HOME {
    width: 100%;
    height: auto;
    border-left: 12px solid rgba(250, 236, 219, 1);
}

.line_MAGNIT_EXTRA {
    width: 100%;
    height: auto;
    border-left: 12px solid rgba(255, 237, 0, 0.5);
}

.line_MAGNIT_COSMETIC {
    width: 100%;
    height: auto;
    border-left: 12px solid rgba(251, 223, 235, 1);
}

.line_MAGNIT_PHARMACY {
    width: 100%;
    height: auto;
    border-left: 12px solid rgba(218, 242, 244, 1);
}

.header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn_back {
    padding-left: 25px;
    padding-right: 25px;
}

.menu {
    cursor: pointer;
    width: 12px;
    height: 7px;
    margin-left: 11px;
    border-radius: 30px;
    border: 0 solid;
}

.checkbox {
    width: 24px;
    height: 24px;
}

.title_header {
    width: 100%;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 30px;
}

.title_header_HOME_PAGE {
    display: flex;
    justify-content: space-between;
    margin-left: 24px;
    margin-right: 15px;
    width: 100%;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 30px;
}

.icon_search {
    cursor: pointer;
    margin-right: 10px;
    border-radius: 30px;
    border: 0 solid;
    background: initial;
}

.icon-shop {
    height: 50px;
    width: 50px;
    margin-top: 4px;
    margin-left: -5px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 50%;
}

.icon-shop_img {
    padding-left: 10px;
    padding-top: 10px;
    height: 30px;
}

.icon_price {
    flex-wrap: wrap;
    font-family: 'Geometria Narrow', sans-serif;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 35px;
    width: 87px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
    font-weight: bold;
}

.discount_text {
    color: #ED1925;
}

.shop {
    cursor: pointer;
    margin-right: 15px;
    border-radius: 30px;
    border: 0 solid;
    background: initial;
}

.shop_box {
    display: flex;
}

.total_amount {
    position: absolute;
    padding-top: 2px;
    margin-left: 13px;
    margin-top: -6px;
    font-family: 'Geometria Narrow', sans-serif;
    font-size: 13px;
    width: 18px;
    height: 17px;
    background: red;
    border-radius: 50%;
    color: white;
}

.search {
    width: 90%;
    border: 0 solid;
    font-size: 18px;
}

.container_search {
    background: white;
    z-index: 1;
    width: 66%;
    top: 68px;
    left: 15%;
    position: absolute;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    height: 51px;
    padding-left: 0;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: 0 solid;
}


.container_menu {
    padding: 18px;
    z-index: 2;
    width: 85%;
    height: auto;
    background: #fff;
    border: 0 solid;
    border-radius: 6px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 73px;
    left: 1%;
    box-shadow: 0 9px 30px #00000040;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 515px;
}

.search_wrapper {
    padding: 10px;
    min-width: 200px;
    width: 80%;
    display: flex;
    align-items: center;
}

/* .categories{
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Geometria Narrow', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
} */

.icon_x {
    padding-right: 15px;
    right: 0;
    position: absolute;
    cursor: pointer;
}

.container_products {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
    position: relative;
    display: flex;
}

/* .product{
  width: 170px;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  padding-top: 30px;
  padding-left: 11px;
  padding-right: 5px;
} */

.product_wrapper {
    width: 176px;
    height: 50px;
    z-index: 1;
    justify-content: space-between;
    margin-top: -29px;
    display: flex;
}

.icon_product {
    margin-top: 10px;
    position: relative;
    z-index: -1;
}

.description {
    width: 176px;
    padding-top: 20px;
    padding-bottom: 40px;
    font-family: 'Geometria Narrow', sans-serif;
    font-size: 16px;
    line-height: 120%;
}

.text_bold {
    font-weight: 700;
}

.text_normal {
    font-weight: 400;
}

.container_counter {
    margin-top: -29px;
    margin-left: -11px;
}

.container_add_shop {
    display: flex;
}

/* .icon_plus{
  position: relative;
  z-index: 1;
  margin-left: 3px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: white;
} */

/* .plus{
  cursor: pointer;
  padding-top: 10px;
  padding-left: 10px;
}

.minus{
  cursor: pointer;
  padding-top: 16px;
  padding-left: 10px;
} */

.icon_minus {
    z-index: 1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background: white;
}

.circle {
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    border-radius: 50%;
    width: 35px;
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    background: red;
}

.number {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: white;
}

.btn_filter {
    cursor: pointer;
    position: fixed;
    z-index: 1;
    margin-top: 46px;
    margin-right: 24px;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0 solid transparent;
    background: #FFFFFF;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);

}

.delete_product {
    margin-left: 149px;
    right: 0;
}

.basket__title_Home {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 490px;
    margin-right: auto;
    margin-left: auto;
    height: 58px;
    background: #FAECDB;
    display: flex;
    align-items: center;
}

.basket__wrapper_text {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.basket__total_price_format {
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
}

.basket__total_discount_format {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #ED1925;
}

.icon_product_basket {
    margin-top: -7px;
    position: relative;
    z-index: -1;
}

.container_filter {
    z-index: 3;
    width: 93%;
    height: auto;
    background: #fff;
    border: 0 solid;
    border-radius: 6px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 20px;
    left: 4%;
    box-shadow: 0 9px 30px #00000040;
}

.header_filter {
    padding: 25px;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E8E8E8;
}

.gray_line {
    width: 100%;
    border-top: 1px solid #E8E8E8;
}

.filter_fix {
    position: fixed;
    width: 100%;
    top: 54px;
    left: 0;
    z-index: 2;
}

.subtitle_filter {
    font-family: 'Geometria-Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    padding: 25px;
    margin-bottom: 50px;
}

.order_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 18px;
}

.order_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_text {
    padding-left: 11px;
    font-family: 'Geometria-Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}

.btn_apply {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 10px 15px;
    width: 128px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 0 solid transparent;
}

.btn_clean {
    position: absolute;
    right: 0;
    margin-top: 11px;
    margin-right: 17px;
    width: 22px;
    height: 24px;
    cursor: pointer;
}

.container_btns_for_filter {
    width: 176px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: 'Geometria Narrow', sans-serif;
    font-size: 20px;
    color: #ED1925;
    display: flex;
    justify-content: space-between;
}

.count-sale {
    height: 150px;
    border-bottom: 1px solid #E8E8E8;
}

.count-price {
    height: 150px;
    border-bottom: 1px solid #E8E8E8;
}

.container_basket {
    z-index: 3;
    width: 93%;
    height: auto;
    background: #fff;
    border: 0 solid;
    border-radius: 6px;
    margin-top: -51px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 72px;
    left: 1%;
    box-shadow: 0 9px 30px #00000040;
}

.container_basket_HOME_PAGE {
    z-index: 3;
    width: 93%;
    height: auto;
    background: #fff;
    border: 0 solid;
    border-radius: 6px;
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 72px;
    left: 1%;
    box-shadow: 0 9px 30px #00000040;
}

.total_price {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    border-bottom: 1px solid #E8E8E8;
}

.info_text {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #797979;
}

.send_products {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 15px;
    gap: 10px;
    width: 124px;
    margin-left: auto;
    margin-right: auto;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 0;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.product_image {
    width: 170px;
}

.productBasket {
    padding-top: 20px;
    width: 178px;
    margin-right: auto;
    margin-left: auto;
}

.header_basket {
    display: flex;
    justify-content: space-between;
    padding: 23px;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}

.basket_wrapper {
    max-width: 490px;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
    position: relative;
    overflow: auto;
    max-height: 510px;
}

.basket_clean {
    cursor: pointer;
    display: flex;
    width: 100%;
    max-width: 205px;
    height: 44px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
    font-family: 'Geometria Narrow', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    align-items: center;
    color: #9C9C9C;
}

@media screen and (max-height: 740px) {
    .basket_wrapper {
        max-height: 366px;
    }
}

@media screen and (max-height: 667px) {

    .categories {
        font-size: 15px;
    }

    .container_menu {
        padding: 10px;
        max-height: 450px;
    }

    .title_header {
        font-size: 15px;
        line-height: 20px;
    }

    .header_filter {
        padding: 18px;
        font-size: 15px;
    }

    .subtitle_filter {
        padding: 18px;
        font-size: 15px;
    }

    .count-sale {
        height: 115px;
    }

    .count-price {
        height: 115px;
    }

    .header_basket {
        font-size: 15px;
        padding: 16px;
    }

    .order_wrapper {
        padding: 8px;
    }

    .send_products {
        padding: 7px 20px;
        width: 115px;
        height: 35px;
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .basket_wrapper {
        max-height: 395px;
    }

    .basket_clean {
        max-width: 155px;
        font-size: 15px;
        height: 35px;
    }

    .total_price {
        height: 30px;
        font-size: 15px;
    }

    .info_text {
        width: 268px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 0;
        padding-bottom: 5px;
    }
}

@media screen and (max-height: 480px) {

    .container_menu {
        padding: 10px;
        max-height: 368px;
    }

    .container_filter {
        margin-top: 10px;
    }

    .btn_filter {
        margin-top: 44px;
        margin-right: 4px;
    }

    .header_filter {
        padding: 16px;
        font-size: 14px;
    }

    .subtitle_filter {
        padding: 8px;
        font-size: 14px;
    }

    .multi-slider {
        margin-top: 17px;
    }

    .count-sale {
        height: 110px;
    }

    .count-price {
        height: 90px;
    }

    .order_wrapper {
        padding: 5px;
    }

    .order_text {
        font-size: 11px;
    }

    .container_btns_for_filter {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 15px;
    }

    .title_header_HOME_PAGE {
        margin-left: 10px;
        margin-right: 5px;
        font-size: 14px;
    }

    .title_header {
        font-size: 14px;
        line-height: 18px;
    }

    .btn_back {
        padding-left: 12px;
        padding-right: 12px;
    }

    .menu {
        margin-left: 5px;
    }

    .container_MAGNIT_HOME {
        border-radius: 0;
    }

    .container_MAGNIT_EXTRA {
        border-radius: 0;
    }

    .container_MAGNIT_COSMETIC {
        border-radius: 0;
    }

    .container_MAGNIT_PHATMACY {
        border-radius: 0;
    }

    .header_basket {
        font-size: 14px;
        padding: 12px;
    }

    .send_products {
        margin-top: 5px;
        margin-bottom: 0;
        padding: 9px 15px;
        width: 101px;
        height: 40px;
        font-size: 14px;
    }

    .info_text {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .total_price {
        height: 20px;
        font-size: 14px;
    }

    .basket_clean {
        height: 30px;
        max-width: 148px;
        font-size: 14px;
    }

    .basket_wrapper {
        max-height: 250px;
    }

    .basket__title_Extra {
        height: 45px;
    }

    .myModalContent {
        width: var(--width-content);
        padding: 25px;
        background: rgb(248, 248, 248);
        border-radius: 16px 16px 0 0;
    }

    .myModal.active {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

}

.categories {
    margin-bottom: 14px;
}

@media (min-width: 480px) {
    .categories {
        margin-bottom: 44px;
    }
}

.categories-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;
    width: 100%;
}

@media (min-width: 480px) {
    .categories-loader {
        height: 98px;
    }
}


.categories__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin: 0 16px 14px;
    color: var(--text);
}

@media (min-width: 480px) {
    .categories__title {
        margin: 0 20px 24px;
        font-size: 27px;
        line-height: 34px;
    }
}

.categories__block {
    display: flex;
    flex-wrap: nowrap;
    gap: 6px;
    margin: 0 16px 6px;
}

@media (min-width: 480px) {
    .categories__block {
        margin: 0 20px 6px;
    }
}


.categories__scroll {
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media (min-width: 480px) {
    .categories__scroll {
        gap: 10px;
    }
}

.categories__scroll::-webkit-scrollbar {
    display: none;
}
.category {
  display: flex;
  padding: 10px 20px;
  background: #F1F1EF;
  border-radius: 60px;
  color: var(--text);
  transition: background-color .2s;
  white-space: nowrap;
  font-family: 'Geometria-Narrow';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}

.category.active {
  background-color: #FBECCF;
}

@media (max-width: 390px){
  .category {
    display: flex;
    padding: 5px 10px;
    background: #F1F1EF;
    border-radius: 40px;
    color: var(--text);
    transition: background-color .2s;
    white-space: nowrap;
    font-family: 'Geometria-Narrow';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
  }
}
.shop-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px 20px;
}

@media (min-width: 480px) {
    .shop-selected {
        margin: 0 20px 50px;
    }
}

.shop-selected__logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

@media (min-width: 480px) {
    .shop-selected__logo {
        width: 70px;
        height: 70px;
        margin-right: 20px;
    }
}

.shop-selected__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    max-width: 70%;
    word-wrap: break-word
}

@media (min-width: 480px) {
    .shop-selected__title {
        font-size: 22px;
        line-height: 28px;
    }
}

.shop-selected__icon {
    width: 16px;
    height: 16px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/right-arrow.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    margin-left: auto;
}

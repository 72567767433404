
.basket__total-price {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.basket__total-price p {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: white;
}

@media (min-width: 480px) {
    .basket__total-price p {
        font-size: 30px;
        line-height: 36px;
    }
}

.basket__under-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 24px 24px 40px;
}

.mt {
    margin-top: 100px
}

.basket__button {
    width: fit-content;
    margin: 20px auto 20px;
    font-size: 16px;
    line-height: 20px;
    color: black;
    padding: 12px 24px;
    cursor: pointer;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    background-color: white;
    text-transform: uppercase;
}

.basket__send-text {
    font-size: 21px;
    line-height: 26px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.basket__send-text b {
    font-weight: 800;
    display: contents;
}

@media (min-width: 480px) {
    .basket__send-text {
        font-size: 25px;
        line-height: 31px;
    }
}
.modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.35);
    z-index: 999;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.modal__content {
    position: absolute;
    width: 100%;
    border-radius: 20px;
    background-color: var(--white);
    left: 0;
    bottom: 0;
    padding: 16px;
}

@media (min-width: 480px) {
    .modal__content {
        border-radius: 40px;
        padding: 20px;
    }
}

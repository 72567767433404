.wrapper {
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    padding-top: 80px;
    padding-bottom: 20px;
}

@media (min-width: 480px) {
    .wrapper {
        min-height: 100vh;
        padding-top: 96px;
    }
}
.input {
    width: 100%;
    height: 50px;
    padding: 10px 46px 10px 30px;
    border: 1px solid #CECECE;
    border-radius: 50px;
    background-color: #FAFAFA;
    font-size: 18px;
}

@media (min-width: 480px) {
    .input {
        height: 66px;
        font-size: 24px;
    }
}

.input__wrapper {
    position: relative;
    width: 100%;
}

.input__close-icon {
    width: 24px;
    height: 24px;
    background-color: var(--text);
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
}
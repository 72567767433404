.filter {
    display: flex;
    align-items: center;
    margin: 0 16px 10px;
    flex-wrap: wrap;
}

@media (min-width: 480px) {
    .filter {
        margin: 0 20px 10px;
    }
}

.filter__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-right: auto;
}

@media (min-width: 480px) {
    .filter__title {
        font-size: 27px;
        line-height: 34px;
    }
}

.filter__search-icon {
    width: 24px;
    height: 24px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/search.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    margin-left: 30px;
}

@media (min-width: 480px) {
    .filter__search-icon {
        width: 34px;
        height: 34px;
        margin-left: 40px;
    }
}

.filter__icon-wrap {
    cursor: pointer;
    margin-left: 30px;
    padding: 10px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 480px) {
    .filter__icon-wrap {
        margin-left: 40px;
    }
}

.filter__icon-wrap__active {
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.25);
}

.filter__icon {
    width: 20px;
    margin-top: 2px;
    height: 18px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/filter.svg);
    mask-repeat: no-repeat;

}

@media (min-width: 480px) {
    .filter__icon {
        width: 30px;
        height: 30px;
        margin-left: 40px;
    }
}

.filter__outside-icon {
    mask-image: url(/public/img/filter.svg);
}

.filter__full-icon {
    mask-image: url(/public/img/filterFull.svg);
}


.filter__input {
    width: 100%;
    margin-top: 16px;
}